import { humanDate, humanTime } from "./menu-operationplans.js";
import { get, post } from "./request.js";
import serverTime from "./server-time.js";

const { alert, confirm, redom, turf } = window;
const { el, setChildren } = redom;

// TEXTUAL_RESTRICTION, AUTHORITY_REQUIREMENTS
// not rejective

export default class OperationPlan {
  constructor({ app, api, i18n }) {
    const { HOST } = window.ENV;
    this.app = app;
    this.api = api;
    this.i18n = i18n;
    this.el = el(
      ".operation-plan",
      (this.mainTitle = el("h2")),
      (this.title = el("h3")),
      el(
        ".menu-content",
        (this.cancel = el("button.button.red", {
          style: { marginBottom: ".5rem", marginRight: ".25rem" },
        })),
        (this.pause = el("button.button.blue", {
          style: {
            display: "none",
            marginBottom: ".5rem",
            marginRight: ".25rem",
          },
        })),
        (this.action = el("button.button.white", {
          style: { marginBottom: ".5rem" },
          innerHTML: '<i class="ti ti-loader-2 ti-spin"></i>',
        })),
        (this.emergencyReason = el("select", { style: { display: "none" } })),
        el(
          "table.table",
          el(
            "tr",
            (this.startTimeText = el("td")),
            (this.startTime = el("td.right"))
          ),
          el(
            "tr",
            (this.endTimeText = el("td")),
            (this.endTime = el("td.right"))
          ),
          el(
            "tr",
            (this.durationText = el("td")),
            (this.duration = el("td.right"))
          ),
          el(
            "tr",
            (this.maxAltitudeText = el("td")),
            (this.maxAltitude = el("td.right"))
          ),
          el(
            "tr",
            (this.typeOfOperationText = el("td")),
            (this.typeOfOperation = el("td.right"))
          )
        ),
        (this.message = el(".message", { style: { overflow: "auto" } })),
        (this.openRemarks = el("button.button", {
          style: { marginTop: "1rem" },
        })),
        (this.remarks = el(".remarks")),
        (this.checklist = el(".checklist"))
      )
    );

    this.openRemarks.onclick = async (e) => {
      e.actionClick = true;

      const { data } = this;

      const { operationplansLookup, menuId } = data;
      const operationPlan = operationplansLookup[menuId] || data.operationPlan;

      if (this.remarks.style.display !== "") {
        this.openRemarks.textContent = i18n("remarks.hide");
        this.openRemarks.classList.add("grey");
        this.remarks.style.display = "";
        this.remarks.textContent = "";

        const { operationPlanId } = operationPlan;

        const data = JSON.parse(await get(`${HOST}utm/log/${operationPlanId}`));

        if (data.length === 0) {
          data.push({});
        }

        const log = data[data.length - 1];

        const textarea = document.createElement("textarea");

        textarea.textContent = log.additionalInformation;

        this.remarks.appendChild(textarea);

        const br = document.createElement("br");
        this.remarks.appendChild(br);

        const save = document.createElement("button");
        save.classList.add("button");
        save.textContent = i18n("save");
        this.remarks.appendChild(save);

        save.onclick = async (e) => {
          e.actionClick = true;

          if (textarea.value === textarea.textContent) {
            this.remarks.style.display = "none";
            this.openRemarks.textContent = i18n("remarks.open");
            return;
          }
          const now = await serverTime();
          if (!log.operationPlanId) {
            log.operationPlanId = operationPlanId;
            log.creationDateTime = now;
          }
          log.updateDateTime = now;
          log.additionalInformation = textarea.value;

          await post(`${HOST}utm/log/${operationPlanId}`, {
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(log),
          });
          this.remarks.style.display = "none";
          this.openRemarks.textContent = i18n("remarks.open");
        };
      } else {
        this.openRemarks.textContent = i18n("remarks.open");
        this.openRemarks.classList.remove("grey");
        this.remarks.style.display = "none";
      }
    };
    this.remarks.style.display = "none";
  }

  async update(data) {
    const { i18n } = this;
    const { operationplansLookup, menuId } = data;
    const {
      HOST,
      MAX_ACTIVATION_LEAD_TIME,
      EMERGENCY_REPORTING,
      HIDE_NONREJECTIVE_CONFLICTS,
    } = window.ENV;

    const operationPlan = operationplansLookup[menuId] || data.operationPlan;

    this.operationPlan = operationPlan;

    this.mainTitle = i18n("operationplan");

    this.startTimeText.textContent = i18n("operationplan.startTime");
    this.endTimeText.textContent = i18n("operationplan.endTime");
    this.durationText.textContent = i18n("operationplan.duration");
    this.maxAltitudeText.textContent = i18n("operationplan.maxAltitude");
    this.typeOfOperationText.textContent = i18n("operationplan.operationType");
    if (this.remarks.style.display !== "") {
      this.openRemarks.textContent = i18n("remarks.open");
    }
    this.cancel.textContent = i18n("operationplan.cancel");

    if (!operationPlan) {
      return;
    }

    const volumes =
      operationPlan.operationVolumes &&
      operationPlan.operationVolumes.map((volume) =>
        turf.feature(volume.operationGeometry.geom)
      );

    const {
      publicInfo = {},
      operationVolumes = [],
      operationPlanId,
      operationTrajectory = [],
      rejected,
      state,
      typeOfOperation,
    } = operationPlan;

    const { droneFlight } = operationPlan || {};

    if (droneFlight && state === "ACTIVATED") {
      this.pause.style.display = "";
      if (droneFlight.flightState === "ONGROUND") {
        this.pause.textContent = i18n("operationplan.resume");
      } else {
        this.pause.textContent = i18n("operationplan.pause");
      }
      this.pause.onclick = async () => {
        const { droneFlight } = this.operationPlan || {};

        if (droneFlight) {
          droneFlight.flightState =
            droneFlight.flightState === "ONGROUND" ? "AIRBORNE" : "ONGROUND";

          await fetch(`${HOST}utm/drone-flight-notify`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(droneFlight),
          });
          this.pause.textContent =
            droneFlight.flightState === "ONGROUND"
              ? i18n("operationplan.resume")
              : i18n("operationplan.pause");
          this.api.fetchPlans(true);
        }
      };
    } else {
      this.pause.style.display = "none";
      this.pause.onclick = () => {};
    }

    if (operationPlanId !== this.operationPlanId) {
      this.operationPlanId = operationPlanId;
      if (volumes) {
        const geojson = {
          type: "FeatureCollection",
          features: volumes,
        };
        this.app.map.map.getSource("highlight").setData(geojson);

        const bbox = turf.bbox(geojson);

        setTimeout(() => {
          this.app.map.map.fitBounds(bbox, { padding: 32, maxZoom: 15 });
        }, 0);
      } else {
        this.app.map.map.getSource("highlight").setData({
          type: "FeatureCollection",
          features: [],
        });
      }
    }

    const { title = "" } = publicInfo;
    const startTime = new Date(operationVolumes[0].timeBegin);
    const endTime = new Date(
      operationVolumes[operationVolumes.length - 1].timeEnd
    );

    const duration = Math.round((endTime - startTime) / 1000);
    let maxAltitude = Math.max(
      ...operationPlan.operationVolumes.map((volume) => {
        const { operationGeometry } = volume;
        const { maxAltitude } = operationGeometry;
        const { altitudeValue, unitsOfMeasure } = maxAltitude;

        return altitude(altitudeValue, unitsOfMeasure);
      })
    );

    if (maxAltitude == null) {
      maxAltitude = i18n("unknown");
    }

    this.title.textContent = title || i18n("unnamed");
    this.startTime.textContent =
      humanDate(new Date(startTime)) + " " + humanTime(new Date(startTime));
    this.endTime.textContent =
      humanDate(new Date(endTime)) + " " + humanTime(new Date(endTime));
    this.duration.textContent = humanDuration(duration);
    this.maxAltitude.textContent = maxAltitude;
    this.typeOfOperation.textContent =
      typeOfOperation === "REMOTELY_PILOTED_VLOS" ? "VLOS" : "BVLOS";

    this.checklist.textContent = "";

    this.cancel.onclick = async (e) => {
      e.actionClick = true;

      const onclick = this.cancel.onclick;
      const textContent = this.cancel.textContent;

      if (confirm(i18n("operationplan.cancel.confirm"))) {
        this.cancel.onclick = () => {};
        this.cancel.innerHTML = '<i class="ti ti-loader-2 ti-spin"></i>';

        try {
          await post(`${HOST}utm/operationplan/${operationPlanId}/cancel`, {
            body: JSON.stringify(operationPlan),
          });
          this.api.menuSection = "operationplans";
          this.api.menuId = "";
          this.api.update();
        } catch (err) {
          alert(err);
        }
        this.cancel.onclick = onclick;
        this.cancel.textContent = textContent;
      }
    };

    let newStartTime;

    try {
      if (operationPlan.authorization) {
        const startTime = operationPlan.operationVolumes[0].timeBegin;

        for (const op of operationPlan.authorization.alternativeOPs || []) {
          const _newStartTime = op.operationVolumes[0].timeBegin;

          if (_newStartTime !== startTime) {
            newStartTime = new Date(_newStartTime);
          }
        }
      }
    } catch (err) {
      console.error(new Error(err));
    }

    if (state === "CLOSED") {
      this.action.textContent = i18n("operationplan.resubmit");
      this.action.onclick = async (e) => {
        e.actionClick = true;

        this.api.menuOpened = false;

        if (this.app.fly.view) {
          this.app.fly.view.button.click();
        }

        await new Promise((resolve) => {
          setTimeout(resolve, 100);
        });

        const { trajectoryElements } = operationTrajectory;
        const { latitude: startLat, longitude: startLng } =
          trajectoryElements[0];
        const { latitude: endLat, longitude: endLng } =
          trajectoryElements[trajectoryElements.length - 1];

        this.api.drawingMode =
          trajectoryElements.length > 1
            ? startLat === endLat && startLng === endLng
              ? "polygon"
              : "line"
            : "circle";

        this.app.draw.view[this.api.drawingMode].click();

        this.api.currentDrawing = operationTrajectory.trajectoryElements.map(
          ({ latitude, longitude }) => {
            return { lng: longitude, lat: latitude };
          }
        );

        await new Promise((resolve) => {
          setTimeout(resolve, 100);
        });

        this.api.update();

        await new Promise((resolve) => {
          setTimeout(resolve, 100);
        });

        this.app.map.map.fitBounds(
          [
            [
              Math.min(...this.api.currentDrawing.map((point) => point.lng)),
              Math.min(...this.api.currentDrawing.map((point) => point.lat)),
            ],
            [
              Math.max(...this.api.currentDrawing.map((point) => point.lng)),
              Math.max(...this.api.currentDrawing.map((point) => point.lat)),
            ],
          ],
          { padding: 32, maxZoom: 15 }
        );
        this.api.resubmitPlan = operationPlan;
        this.app.draw.view.create.click();
      };
    }

    if (rejected || newStartTime) {
      const conflictLookup = {};
      let message = (
        (
          (operationPlan.authorization &&
            operationPlan.authorization.conflicts) ||
          []
        ).concat(
          (operationPlan.activation && operationPlan.activation.conflicts) || []
        ) || []
      ).reduce((message, conflict) => {
        const nonrejectiveType =
          conflict.conflictType === "TEXTUAL_RESTRICTION" ||
          conflict.conflictType === "AUTHORITY_REQUIREMENTS";

        if (nonrejectiveType && HIDE_NONREJECTIVE_CONFLICTS) {
          return;
        }

        const color = nonrejectiveType
          ? "hsl(60, 50%, 50%)"
          : "hsl(0, 50%, 50%)";

        if (
          conflict.conflictType === "TEXTUAL_RESTRICTION" &&
          conflict.objectType === "UAS_ZONE"
        ) {
          /*
          const phrase = 'operationplan.conflict.textualRestriction.uasZone';
          message += `<br><div style="float: right; width: 1em; height: 1em; border-radius: 50%; background-color: ${color}; margin-left: .5rem;"></div><b>${i18n(phrase, conflict.message).replace('{{objectId}}', conflict.objectId)}</b><br>`;
          */
        } else {
          if (conflict.message) {
            if (!conflictLookup[conflict.message]) {
              const { objectId, operationPlanDetails } = conflict;
              conflictLookup[conflict.message] = true;
              message += `<br><div style="float: right; width: 1em; height: 1em; border-radius: 50%; background-color: ${color}; margin-leftshou: .5rem;"></div><b>${conflict.message
                .replace("OPERATION_PLAN", "operation plan")
                .replace(
                  objectId,
                  (operationPlanDetails || {}).title || objectId
                )}</b><br>`;
            }
          }
        }

        /* if (conflict.conflictType === 'AUTHORITY_REQUIREMENTS') {
          (conflict.authorityRequirements || conflict.authorityRequirement || []).forEach((requirement) => {
            const { contactName, email, name, service, phone, purpose } =
              requirement;

            const procedure = {
              AUTHORIZATION: 'Please ask for authorization:',
              NOTIFICATION: 'Please inform the organization about the flight:',
              INFORMATION: 'Please ask the organization for information:'
            };

            message += `<br>${procedure[purpose]}<br>${name} ${service}<br>${contactName}<br>${phone}<br>${email}<br>`;
          });
        } */

        return message;
      }, "");

      if (newStartTime) {
        message += `<span style="display: block; font-size: .75rem; font-weight: 600; padding-top: .75rem; padding-left: .25rem;">${i18n(
          "operationplan.newStartTime"
        )}: ${humanDate(newStartTime)} ${humanTime(newStartTime)}</span>`;
      }

      if (message !== this._message) {
        this.message.innerHTML = message;
        this._message = message;
      }
      this.action.style.display = "";
      this.cancel.style.display = "none";
    } else {
      this.message.textContent = "";
      this._message = "";
      if (state === "PROPOSED") {
        this.action.textContent = i18n("operationplan.waitingForApproval");
        this.action.onclick = (e) => {
          e.actionClick = true;
        };
      } else if (state === "APPROVED") {
        if (state === this._lastState) {
          this.action.innerHTML = '<i class="ti ti-loader-2 ti-spin"></i>';
          return;
        }

        if (operationPlan.operator === this.api.operator) {
          this.checklist.innerHTML = i18n("checklist");
        }

        if (this.activating) {
          this.action.innerHTML = '<i class="ti ti-loader-2 ti-spin"></i>';
        } else {
          this.action.textContent = i18n("operationplan.activatePlan");
        }

        this.action.onclick = async (e) => {
          if (this.activating) {
            return;
          }
          if (this.action.querySelector("i.ti-spin")) {
            return;
          }
          e.actionClick = true;

          this._lastState = state;

          this.action.textContent = i18n("operationplan.activating");

          const { operationVolumes } = operationPlan || {};
          const startTime = new Date(operationVolumes[0].timeBegin);

          if (startTime) {
            const now = await serverTime();

            if (
              startTime.getTime() >
              now + MAX_ACTIVATION_LEAD_TIME * 60 * 1000
            ) {
              this.action.textContent = i18n("operationplan.activatePlan");
              return alert(
                i18n("operationplan.error.startAfterMaxActivationLeadTime")
              );
            }
          }

          this.activating = true;

          this.update(this.data);

          try {
            await post(`${HOST}utm/operationplan/${operationPlanId}/activate`, {
              body: JSON.stringify(operationPlan),
            });
          } catch (err) {
            alert(err);
          }
          this.activating = false;
          this.update(this.data);
        };
        this.action.style.display = "";
      } else if (state === "TAKEOFFREQUESTED") {
        this.action.textContent = i18n("operationplan.takeoffRequested");
        this.action.onclick = () => {};
        this.action.style.display = "";
      } else if (state === "ACTIVATED") {
        if (state === this._lastState) {
          this.action.innerHTML = '<i class="ti ti-loader-2 ti-spin"></i>';
          return;
        }
        if (this.activating) {
          this.action.innerHTML = '<i class="ti ti-loader-2 ti-spin"></i>';
        } else {
          this.action.textContent = i18n("operationplan.endFlight");
        }

        this.action.onclick = async (e) => {
          if (this.activating) {
            return;
          }
          if (this.action.querySelector("i.ti-spin")) {
            return;
          }
          e.actionClick = true;

          this._lastState = state;

          this.activating = true;
          this.update(this.data);

          try {
            await post(
              `${HOST}utm/operationplan/${operationPlanId}/endflight`,
              { body: JSON.stringify(operationPlan) }
            );
            this.api.menuSection = "operationplans";
            this.api.menuId = "";
            this.api.update();
          } catch (err) {
            alert(err);
          }
          this.activating = false;

          this.update(this.data);
        };

        if (EMERGENCY_REPORTING) {
          this.cancel.style.display = "";
          this.cancel.textContent = i18n("operationplan.reportEmergency");
          this.emergencyReason.onclick = (e) => {
            e.stopPropagation();
          };
          this.cancel.onclick = (e) => {
            e.stopPropagation();
            if (this.emergencyReason.style.display) {
              this.emergencyReason.style.display = "";
              const closeEvent = (e) => {
                this.emergencyReason.style.display = "none";
                window.removeEventListener("click", closeEvent);
              };
              window.addEventListener("click", closeEvent);
            } else {
              this.emergencyReason.style.display = "none";
            }
            this.emergencyReason.onchange = async () => {
              const text = this.emergencyReason.value;

              if (!text) {
                return;
              }

              if (confirm(i18n("emergency.confirm"))) {
                try {
                  await post(
                    `${HOST}/utm/declareAlert?planId=${operationPlanId}`,
                    {
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        text,
                      }),
                    }
                  );
                } catch (err) {
                  alert(err);
                }

                this.api.currentAlerts.push(text);
              }
            };
            setChildren(
              this.emergencyReason,
              [
                el(
                  "option",
                  {
                    value: null,
                  },
                  `${i18n("emergency.choose")}:`
                ),
              ].concat(
                [
                  "generalEmergency",
                  "lossOfControl",
                  "lossOfPower",
                  "midAirCollision",
                  "obstacleCollision",
                  "personalInjury",
                ].map((phrase) => {
                  const text = i18n(`emergency.${phrase}`);

                  return el(
                    "option",
                    {
                      value: text,
                    },
                    text
                  );
                })
              )
            );
          };
        } else {
          this.cancel.style.display = "none";
        }
      } else {
        this.cancel.style.display = "none";
      }
    }
    if (operationPlan.operator !== this.api.operator) {
      this.openRemarks.style.display = "none";
      this.cancel.style.display = "none";
      this.action.style.display = "none";
    }
    this.data = data;
  }

  async onmount() {
    const { api, i18n } = this;
    api.fetchPlans(true, true);
  }

  onunmount() {
    this.app.map.map.getSource("highlight").setData({
      type: "FeatureCollection",
      features: [],
    });
  }
}

function humanDuration(time) {
  return (
    String(Math.floor(time / 3600)).padStart(2, "0") +
    ":" +
    String(Math.floor(time / 60) % 60).padStart(2, "0")
  );
}

function altitude(value, unit) {
  if (Number.isNaN(Number(value))) {
    return null;
  } else if (unit === "FT") {
    return Math.round(0.3048 * value);
  } else {
    return Math.round(value);
  }
}
